import { useSegment } from '@/composable/Segment/segment.js';
import { SEGMENT_EVENTS } from '@/vue/constants';

export const paymentMethodsHubTracking = async store => {
  const userCompanyUuid = () => store.getters['UserData/userCompanyUuid'];
  const userUuid = () => store.getters['UserData/userUuid'];
  const doesCompanyOwnsData = () => store.getters['UserData/doesCompanyOwnsData'];
  const getCurrentCity = () => store.getters['City/getCurrentCity'];
  const getCurrentProfileType = () => store.getters['Profile/getCurrentProfileType'];

  const currentStore = {
    doesCompanyOwnsData,
    getCurrentCity,
    getCurrentProfileType,
    userCompanyUuid,
    userUuid,
  };

  const { trackSegment } = await useSegment(currentStore);

  const addPaymentMethodAuthorisedTrackEvent = () => ({
    trackingLibrary: trackSegment,
    addPaymentMethodAuthorisedEvent: { name: SEGMENT_EVENTS.ADD_PAYMENT_METHOD_CONFIRMED },
  });

  const launchAddPaymentMethodTrackEvent = () => ({
    trackingLibrary: trackSegment,
    launchAddPaymentMethodEvent: { name: SEGMENT_EVENTS.ADD_PAYMENT_METHOD_CLICKED },
  });

  return {
    addPaymentMethodAuthorisedTrackEvent,
    launchAddPaymentMethodTrackEvent,
  };
};
